<script setup>
defineProps({
  item: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
</script>

<template>
  <div class="w-1/2 p-2 lg:w-1/3">
    <NuxtLinkLocale
      class="animate animate-animated animate-fadeIn hover:(shadow-input-big scale-102) group flex w-full flex-none -translate-y-1 transform cursor-pointer flex-col rounded-lg border p-2 transition duration-300"
      :to="`/advice/${item.slug}`"
    >
      <div>
        <img
          v-if="item.image"
          class="aspect-video w-full rounded object-cover"
          :src="useImageProxy(item.image, 600, 600)"
          :alt="item.title"
        >
        <div
          v-else
          class="aspect-video w-full rounded bg-gray-100 object-cover"
        />
      </div>

      <div class="p-2 gap-4 flex flex-col">
        <div class="min-h-12 md:min-h-14 line-clamp-2 text-base md:text-xl font-bold">
          {{ item.title }}
        </div>
        <div
          class="min-h-10 md:min-h-18 line-clamp-3 text-sm md:text-base"
          v-text="item.description"
        />

        <div class="flex flex-row items-center gap-4">
          <div class="text-sm md:text-base">
            {{ t('advice.read_more') }}
          </div>
          <img
            class="group-hover:(animate-animated animate-infinite animate-shake-x !animate-duration-5000 animate-delay-300) mt-[2px]"
            src="~/assets/images/icons/chevron-right.svg"
            alt="Chevron"
          >
        </div>
      </div>
    </NuxtLinkLocale>
  </div>
</template>
